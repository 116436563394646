import { useLazyQuery } from "@apollo/client";
import { ChallengeTrackingStatus } from "@zeus/index";
import { useEffect, useState } from "react";
import { GQL_USER } from "~/gql/users/users";
import { authClient } from "~/helpers/apollo";
import { useAuth } from "~/helpers/store/auth";
import { useQuiz } from "~/helpers/store/quiz";
import { useTracking } from "~/helpers/store/tracking";
import { useUser } from "~/helpers/store/user";
import CardDraw from "~/pages/authentified/components/CardDraw";
import Hub from "~/pages/authentified/components/Hub";
import Motion from "~/pages/authentified/components/Motion";
import Quiz from "~/pages/authentified/components/Quiz";
import SatisfactionQuiz from "~/pages/authentified/components/SatisfactionQuiz";

function Home() {
  const { user, setUser } = useUser()
  const { payload } = useAuth();
  const { trackings } = useTracking();
  const { quiz, setQuiz } = useQuiz();
  const [cardDraw, setCardDraw] = useState<boolean>(false);

  const [getUser] = useLazyQuery(GQL_USER, {
    client: authClient,
    fetchPolicy: 'network-only', // Force Apollo à interroger le serveur
    onCompleted: (data) => {
      setUser(data.getUser);
    },
  });

  useEffect(() => {
    if (payload?.sub) {
      void getUser({ variables: { id: payload.sub } });
    }
  }, [trackings, payload])

  useEffect(() => {
    if(trackings.length > 0) {
      setCardDraw(false);
      trackings.forEach((_tracking) => {
        if (_tracking.challengeTrackingStatus === ChallengeTrackingStatus.EMPTY) {
          setQuiz({quiz: _tracking.challenge.quizzes[0], trackingId: _tracking.id});
        } else if(_tracking.challengeTrackingStatus === ChallengeTrackingStatus.IN_PROGRESS) {
          for (let _quizResponse of _tracking.quizResponses) {
            if(!_quizResponse.done) {
              const _quiz = _tracking.challenge.quizzes.find((_quiz) => _quiz.id === _quizResponse.quizId);
              if(_quiz) { 
                setQuiz({quiz: _quiz, trackingId: _tracking.id})
                break;
              };
            } else {
              setQuiz(null);
            }
          }
      } 
      })  
    }
  }, [trackings])

  return <>
    {user?.tutorEmail && user?.tutorEmail.length > 0 && !user?.satisfactionQuiz && <SatisfactionQuiz />}
    {user?.satisfactionQuiz && !user?.motionViewed && <Motion />}
    {user?.motionViewed && !user?.firstCardDraw && <CardDraw title="draw-card.message"/>}
    {quiz && <Quiz />}
    {user?.firstCardDraw && user.motionViewed && !quiz && !cardDraw && <Hub setCardDraw={setCardDraw} />}
    {cardDraw && <CardDraw title="common.next-challenge" setCardDraw={setCardDraw}/>}
  </>;
}

export default Home;

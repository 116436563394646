import { RedoOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { FloatButton } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { GQL_STATS_WITH_FILTERS, StoredStats } from '~/gql/stats/stats';
import { authClient } from '~/helpers/apollo';
import { downloadCSV } from '~/utils/exportCsv/utils';
import CommonTable from '~/utils/table/CommonTable';

function StatTable() {
  const [stats, setStats] = useState<StoredStats[]>([]);
  const [statsExported, setStatsExported] = useState<StoredStats[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [sortedInfo, setSortedInfo] = useState<any>();
  const [filterInfo, setFilterInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState('');
  const [skipQuery, setSkipQuery] = useState(true);
  const [yScroll] = useState(true);
  const [xScroll] = useState<string>();
  const { i18n } = useLingui();

  const columns: ColumnsType<StoredStats> = [
    {
      title: `${i18n._('common.question')}`,
      dataIndex: 'question',
      key: 'question',
      width: 250,
      render: (_, { question }) => <p>{question}</p>,
    },
    {
      title: `${i18n._('common.answerNumber')}`,
      dataIndex: 'answerNumber',
      key: 'answerNumber',
      width: 150,
      render: (_, { answerNumber }) => <p>{answerNumber}</p>,
    },
    {
      title: `${i18n._('common.yes')}`,
      dataIndex: 'yes',
      key: 'yes',
      width: 150,
      render: (_, { yes }) => <p>{yes}</p>,
    },
    {
      title: `${i18n._('common.littleYes')}`,
      key: 'littleYes',
      dataIndex: 'littleYes',
      width: 150,
      render: (_, { littleYes }) => <p>{littleYes}</p>,
    },
    {
      title: `${i18n._('common.middle')}`,
      key: 'middle',
      dataIndex: 'middle',
      width: 150,
      render: (_, { middle }) => <p>{middle}</p>,
    },
    {
      title: `${i18n._('common.littleNo')}`,
      key: 'littleNo',
      dataIndex: 'littleNo',
      width: 150,
      render: (_, { littleNo }) => <p>{littleNo}</p>,
    },
    {
      title: `${i18n._('common.no')}`,
      key: 'no',
      dataIndex: 'no',
      width: 150,
      render: (_, { no }) => <p>{no}</p>,
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item }));
  if (xScroll === 'fixed') {
    tableColumns[0].fixed = true;
    tableColumns[tableColumns.length - 1].fixed = 'right';
  }

  const { refetch: fetchStats } = useQuery(GQL_STATS_WITH_FILTERS, {
    client: authClient,
    skip: skipQuery,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      findManyStatWithFiltersInput: {
        skip: page <= 1 ? 0 : (page - 1) * 10,
        take: pageSize,
      },
    },
    onCompleted: (data) => {
      setStats(data.getFilteredStats.stats);
      setTotal(data.getFilteredStats.total);
      setLoading(false);
    },
  });

  const [fetchExportedStats] = useLazyQuery(GQL_STATS_WITH_FILTERS, {
    client: authClient,
    onCompleted: (data) => {
      setStatsExported(data.getFilteredStats.stats);
    },
  });

  const handleRefreshStats = async () => {
    setLoading(true);
    await fetchStats();
    setLoading(false);
  };

  const handleExportStats = async () => {
    await fetchExportedStats({
      variables: {
        findManyStatWithFiltersInput: {
          skip: 0,
          take: 9999,
        },
      },
    });
  };

  function exportCsv() {
    downloadCSV(
      i18n._('common.stats'),
      [
        i18n._('common.question'),
        i18n._('answer.number'),
        i18n._('common.yes'),
        i18n._('common.littleYes'),
        i18n._('common.middle'),
        i18n._('common.littleNo'),
        i18n._('common.no'),
      ],
      statsExported.map((stat) => {
        return [
          stat.question,
          stat.answerNumber,
          stat.yes,
          stat.littleYes,
          stat.middle,
          stat.littleNo,
          stat.no,
        ];
      })
    );
  }

  useEffect(() => {
    setSkipQuery(false);
    void handleRefreshStats();
    return () => setSkipQuery(true);
  }, [page, pageSize, sortedInfo, searchText, filterInfo]);

  useEffect(() => {
    void handleExportStats();
  }, []);

  return (
    <div style={{ paddingTop: ' 25px' }}>
      <CommonTable
        data={stats}
        loading={loading}
        total={total}
        refresh={handleRefreshStats}
        columns={columns}
        xScroll={xScroll}
        yScroll={yScroll}
        showFooter={false}
        showHeader={true}
        headerTitle={i18n._('stats.management')}
        selectionType="checkbox"
        top="none"
        bottom="bottomRight"
        exportButton
        exportCsv={exportCsv}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setSortedInfo={setSortedInfo}
        setFilterInfo={setFilterInfo}
        setSearchText={setSearchText}
        search={false}
      />

      <FloatButton
        onClick={handleRefreshStats}
        icon={<RedoOutlined style={{ fontSize: '20px' }} />}
        type="primary"
        style={{ right: 24, width: '50px', height: '50px', bottom: 24 }}
      />
    </div>
  );
}

export default StatTable;

import { useLazyQuery, useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Background from '@assets/images/background-neutral.png';
import Logo from '@assets/images/logo-defi.png';
import { SText } from '~/utils/ui/utils';
import { authClient } from '~/helpers/apollo';
import { SButton } from '~/pages/authentication/Login';
import { GQL_UPDATE_TUTOR_EMAIL, GQL_USER } from '~/gql/users/users';
import { useUser } from '~/helpers/store/user';
import { useTuteur } from '~/helpers/store/tuteur';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/helpers/store/auth';

function ValidateTutorEmail() {
  const [email, setEmail] = useState('');
  const { user, setUser } = useUser();
  const { i18n } = useLingui();
  const { tuteur, setTuteur } = useTuteur();
  const navigate  = useNavigate();
  const { payload } = useAuth();

  const [updateTutorEmail] = useMutation(GQL_UPDATE_TUTOR_EMAIL, {
    client: authClient,
    onCompleted: (data) => {
      setUser(data.updateTutorEmail);
      setTuteur(data.updateTutorEmail.tutorEmail as string);
      navigate('/home');
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const updateHandler = async () => {
    if (!user?.id) return;
    await updateTutorEmail({
      variables: { userId: user?.id, tutorEmail: tuteur ? tuteur : email },
    });
  };

  const [getUser] = useLazyQuery(GQL_USER, {
    client: authClient,
    fetchPolicy: 'network-only', // Force Apollo à interroger le serveur
    onCompleted: (data) => {
      setUser(data.getUser);
    },
  });

  useEffect(() => {
    if (payload?.sub) {
      void getUser({ variables: { id: payload.sub } });
    }
  }, [payload])

  useEffect(() => {
    if(tuteur && tuteur.length > 0 && user?.id) {
      void updateHandler();
    }
  }, [tuteur, user])

  return (
    <SForm onFinish={updateHandler} initialValues={{ remember: true }}>
      <img src={Logo} alt="logo" width="100%" />
      <SText $fontweight="5" $margin="30px" $color="#000">
        {i18n._('validate-tutorEmail.message')}
      </SText>
      <Form.Item
        name="email"
        initialValue={tuteur}
        className="customInput"
        rules={[
          {
            required: true,
            message: i18n._('input.validateTutorEmail.message'),
            pattern: new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
          },
        ]}
      >
        <Input
          disabled={tuteur !== null}
          placeholder={i18n._('common.tutorEmail')}
          value={tuteur ?? ''}
          onChange={(e) => setEmail(e.target.value)}
          size="large"
        />
      </Form.Item>

      <SButton $width="60%">
        <SText $fontsize="20px">{i18n._('common.validate')}</SText>
      </SButton>
    </SForm>
  );
}

export default ValidateTutorEmail;

export const SForm = styled(Form)`
  width: 100%;
  height: 100%;
  background: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;

  & .customInput {
    width: 100%;

    & span:nth-child(1),
    & input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      padding: 0 15px;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid #000;
      text-align: center;
    }
  }
`;

import React from 'react';
import { NavigateFunction, Route } from 'react-router-dom';
import App from '~/pages/App';
import CreateAccount from '~/pages/authentication/CreateAccount';
import Denied from '~/pages/authentication/Denied';
import Logout from '~/pages/authentication/Logout';
import NewPassword from '~/pages/authentication/NewPassword';
import ResetPassword from '~/pages/authentication/ResetPassword';
import PortraitLayout from '~/pages/authentified/PortraitLayout';
import * as HomeConnected from '~/pages/authentified/Home';
import UserTable from '~/pages/backoffice/admin/user/UserTable';
import BackOfficeLayout from '~/pages/backoffice/BackOfficeLayout';
import Files from '~/pages/backoffice/files/Files';
import * as HomeBackOffice from '~/pages/backoffice/Home';
import ImAuth from '~/pages/utils/ImAuth';
import NotFound from '~/pages/utils/NotFound';
import Error from './Error';
import GetTuteurEmail from '~/pages/utils/GetTuteurEmail';
import ValidateTutorEmail from '~/pages/authentified/components/ValidateTutorEmail';
import StatTable from '~/pages/backoffice/admin/stats/StatTable';

export enum Role {
  Admin = 'Admin',
  Formateur = 'Formateur',
  User = 'User',
  Guest = 'Guest',
}

type RouteParam = {
  path: string;
  element: React.JSX.Element;
  rolesAuthorize: Role[];
};

export const routingDefault = (role: Role, navigate: NavigateFunction) => {
  switch (role) {
    case Role.User:
      navigate('/tutorEmail');
      break;
    case Role.Admin:
    case Role.Formateur:
      navigate('/bo/users');
      break;
    default:
      navigate('/');
      break;
  }
};

// Routes à définir
// If empty array, no auth required
const routes: RouteParam[] = [
  {
    path: '/',
    element: (
      <PortraitLayout>
        <App />
      </PortraitLayout>
    ),
    rolesAuthorize: [],
  },
  {
    path: '/login',
    element: <GetTuteurEmail />,
    rolesAuthorize: [],
  },
  {
    path: '/bo/users',
    element: (
      <BackOfficeLayout>
        <UserTable />
      </BackOfficeLayout>
    ),
    rolesAuthorize: [Role.Admin],
  },
  {
    path: '/bo/stats',
    element: (
      <BackOfficeLayout>
        <StatTable />
      </BackOfficeLayout>
    ),
    rolesAuthorize: [Role.Admin],
  },
  {
    path: '/bo/home',
    element: (
      <BackOfficeLayout>
        <HomeBackOffice.default />
      </BackOfficeLayout>
    ),
    rolesAuthorize: [Role.Admin, Role.Formateur],
  },
  {
    path: '/bo/files',
    element: (
      <BackOfficeLayout>
        <Files />
      </BackOfficeLayout>
    ),
    rolesAuthorize: [Role.Admin, Role.Formateur],
  },
  {
    path: '/tutorEmail',
    element: (
      <PortraitLayout>
        <ValidateTutorEmail />
      </PortraitLayout>
    ),
    rolesAuthorize: [Role.Admin, Role.Formateur, Role.User],
  },
  {
    path: '/home',
    element: (
      <PortraitLayout>
        <HomeConnected.default />
      </PortraitLayout>
    ),
    rolesAuthorize: [Role.Admin, Role.Formateur, Role.User],
  },
  {
    path: '/logout',
    element: <Logout />,
    rolesAuthorize: [],
  },
  {
    path: '/denied',
    element: <Denied />,
    rolesAuthorize: [],
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    rolesAuthorize: [],
  },
  {
    path: '/new-password',
    element: <NewPassword />,
    rolesAuthorize: [],
  },
  {
    path: '/error',
    element: <Error />,
    rolesAuthorize: [],
  },
  {
    path: '/createAccount',
    element: <CreateAccount />,
    rolesAuthorize: [],
  },
  {
    path: '*',
    element: <NotFound />,
    rolesAuthorize: [],
  },
];

export const renderRoutes = () => {
  return routes.map((r, i) => {
    return (
      <Route
        path={r.path}
        key={i}
        element={
          <>
            <ImAuth rolesAuthorize={r.rolesAuthorize} />
            {r.rolesAuthorize.includes(Role.Guest) ? (
              <>{r.element}</>
            ) : (
              r.element
            )}
          </>
        }
      />
    );
  });
};

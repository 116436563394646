import { useLazyQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Form, Input, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { GQL_LOGIN, GQL_LOGIN_UNIQUE } from '~/gql/auth/auth';
import { Payload, useAuth } from '~/helpers/store/auth';
import { Role, routingDefault } from '~/pages/utils/router';
import { parseJwt } from '~/utils/jwt';
import Background from '@assets/images/background-neutral.png';
import Logo from '@assets/images/logo-defi.png';
import { SText } from '~/utils/ui/utils';
import { useUser } from '~/helpers/store/user';
import { useQuiz } from '~/helpers/store/quiz';
import { useTracking } from '~/helpers/store/tracking';
import { env } from '~/helpers/env';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setPayload } = useAuth();
  const { setUser } = useUser();
  const { setQuiz } = useQuiz();
  const { setTrackings } = useTracking();
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const [searchParams] = useSearchParams();
  const [uniqueAuth] = useLazyQuery(GQL_LOGIN_UNIQUE);

  const [login, { loading }] = useLazyQuery(GQL_LOGIN, {
    onCompleted: (data) => {
      if (data.login.__typename === 'Tokens') {
        const dataJwt = parseJwt(data.login.access_token);
        if (!dataJwt) {
          notification.error({
            message: i18n._('common.login'),
            description: i18n._('impossible.read.token'),
            placement: 'topRight',
          });
          return;
        }
        const payload: Payload = {
          access_token: data.login.access_token,
          refresh_token: data.login.refresh_token,
          ...dataJwt,
        };
        notification.success({
          message: i18n._('common.login'),
          description: i18n._('connection.successful'),
          placement: 'topRight',
        });

        setPayload(payload);

        setQuiz(null);
        setTrackings([]);
        setUser(null);

        routingDefault(payload.roles as Role, navigate);
      } else {
        notification.error({
          message: i18n._('common.login'),
          description: i18n._('connection.failed'),
          placement: 'topRight',
        });
      }
    },
    onError: () => {
      notification.error({
        message: i18n._('common.login'),
        description: i18n._('bad.server-login.message'),
        placement: 'topRight',
      });
    },
  });

  const loginHandler = async () => {
    await login({ variables: { email: email, password: password } });
  };

  useEffect(() => {
    const uniqueCode = searchParams.get('uniqueCode');
    if (uniqueCode) {
      uniqueAuth({ variables: { code: uniqueCode } })
        .then((t) => {
          if (!t.data) throw new Error('Error login SSO');
          const payload: Payload = {
            access_token: t.data.uniqueAuthFromSso.access_token,
            refresh_token: 'SSO',
            firstName: t.data.uniqueAuthFromSso.firstName,
            lastName: t.data.uniqueAuthFromSso.lastName,
            exp: 0,
            iat: 0,
            sub: t.data.uniqueAuthFromSso.sub,
            roles: t.data.uniqueAuthFromSso.role,
            email: t.data.uniqueAuthFromSso.email,
          };
          setPayload(payload);

          setQuiz(null);
          setTrackings([]);
          setUser(null);

          routingDefault(payload.roles as Role, navigate);
        })
        .catch(() => {
          navigate('/');
        });
    }
  }, []);

  if (loading) return <p>{i18n._('common.loading')} ...</p>;

  return (
    <SForm onFinish={loginHandler} initialValues={{ remember: true }}>
      <img src={Logo} alt="logo" width="100%" />
      <SText $fontweight="5" $margin="30px">
        {i18n._('login.message')}
      </SText>
      <Form.Item
        name="email"
        className="customInput"
        rules={[
          {
            required: true,
            message: i18n._('input.email.message'),
            pattern: new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
          },
        ]}
      >
        <Input
          placeholder={i18n._('common.email')}
          value={'raphaelmortier@oulook.fr'}
          onChange={(e) => setEmail(e.target.value)}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name="password"
        className="customInput"
        rules={[{ required: true, message: i18n._('input.password') }]}
      >
        <Input
          type="password"
          placeholder={i18n._('common.password')}
          onChange={(e) => setPassword(e.target.value)}
          size="large"
        />
      </Form.Item>
      <SButton className="login-form-button" $width="60%">
        <SText $fontsize="20px">{i18n._('common.validate')}</SText>
      </SButton>
      {env.app.env != 'local' && (
        <SButton
          onClick={() =>
            window.location.replace(`/${env.app.env}/api/auth/sso/saml/login`)
          }
          className="login-form-button"
          $width="60%"
        >
          <SText $fontsize="20px">SSO</SText>
        </SButton>
      )}
    </SForm>
  );
}

export default Login;

export const SForm = styled(Form)`
  width: 100%;
  height: 100%;
  background: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;

  & .customInput {
    width: 100%;

    & span:nth-child(1),
    & input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      padding: 0 15px;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid #000;
      text-align: center;
    }
  }
`;

export const SButton = styled.button<{ $disabled?: boolean; $width: string }>`
  all: unset;
  width: ${({ $width }) => $width};
  height: 43px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.85);
  transform: skew(-18deg);
  border-radius: 5px;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { scalars } from '~/gql/utils/scalars';

const statsSelector = Selector('Stats')({
    question: true,
    answerNumber: true,
    yes: true,
    littleYes: true,
    middle: true,
    littleNo: true,
    no: true
});


const statsPaginationSelector = Selector('StatsPagination')({
  stats: statsSelector,
  total: true,
});

export const GQL_STATS_WITH_FILTERS = typedGql('query', { scalars })({
    getFilteredStats: [
    {
      findManyStatWithFiltersInput: $('findManyStatWithFiltersInput', 'FindManyStatWithFiltersInput!'),
    },
    statsPaginationSelector,
  ],
});


export type StoredStats = InputType<
  GraphQLTypes['Stats'],
  typeof statsSelector
>;

import Background from '@assets/images/background-neutral.png';
import styled from 'styled-components';
import CardBack from '@assets/images/card-back.png';
import { SText } from '~/utils/ui/utils';
import { useLingui } from '@lingui/react';
import { useMutation, useQuery } from '@apollo/client';
import { GQL_GET_THEMATICS, StoredThematic } from '~/gql/thematic/thematic';
import { authClient } from '~/helpers/apollo';
import { useState } from 'react';
import { resolveImg } from '~/utils/imgResolver';
import { SButton } from '~/pages/authentication/Login';
import { ChallengeDifficulty } from '@zeus/index';
import { GQL_UPDATE_FIRST_CARD_DRAW } from '~/gql/users/users';
import { useTracking } from '~/helpers/store/tracking';
import { useUser } from '~/helpers/store/user';
import { GQL_CREATE_TRACKING } from '~/gql/tracking/tracking';

interface IProps {
    title: string;
    setCardDraw?: (cardDraw: boolean) => void;
}

type CustomThematic = {
    thematic: StoredThematic;
    disabled: boolean;
    difficulty: ChallengeDifficulty;
}

function CardDraw ({ title, setCardDraw }: IProps) {
    const { i18n } = useLingui();
    const [thematics, setThematics] = useState<CustomThematic[]>([]);
    const [clicked, setClicked] = useState(false);
    const [selected, setSelected] = useState<string>('');
    const { user, setUser } = useUser();
    const { trackings, setTrackings } = useTracking();
    
    const getDifficultyChallenge = (thematic: StoredThematic) => {
        const lastChallengeFromUser = user?.trackingEntity?.filter((_tracking) => _tracking.challenge.thematicId === thematic.id).sort((a, b) => a.challenge.id - b.challenge.id)?.at(-1)?.challenge;
        const lastChallengeFromThematic = thematic.challenges.at(-1);

        if(lastChallengeFromUser?.id === lastChallengeFromThematic?.id) {
            return lastChallengeFromUser?.difficulty;
        } else if(lastChallengeFromUser) {
            return thematic.challenges.find((challenge) => challenge.id === lastChallengeFromUser.id + 1)?.difficulty;
        } else {
            return thematic.challenges[0].difficulty;
        }
    }

    const getDisabledChallenge = (thematic: StoredThematic) => {
        const lastChallengeFromUser = user?.trackingEntity?.filter((_tracking) => _tracking.challenge.thematicId === thematic.id).sort((a, b) => a.challenge.id - b.challenge.id)?.at(-1)?.challenge;
        const lastChallengeFromThematic = thematic.challenges.at(-1);

        if(lastChallengeFromUser?.id === lastChallengeFromThematic?.id) {
            return true;
        } else if(lastChallengeFromUser && thematic.challenges.find((challenge) => challenge.id === lastChallengeFromUser.id + 1)?.difficulty === ChallengeDifficulty.COMPLEXE && new Date(user?.unlockedDate as string) > new Date()) {
            return true;
        } else if (thematic.challenges[0].difficulty === ChallengeDifficulty.COMPLEXE && new Date(user?.unlockedDate as string) > new Date() || thematic.challenges[0].difficulty === ChallengeDifficulty.COMPLEXE && !user?.unlockedDate) {
            return true;
        } else {
            return false;
        }
    }

    useQuery(GQL_GET_THEMATICS, {
        client: authClient,
        onCompleted: (data) => {
            setThematics(data.getThematics.map((thematic) => ({
                thematic, 
                disabled: getDisabledChallenge(thematic) as boolean,
                difficulty: getDifficultyChallenge(thematic) as ChallengeDifficulty,
            })));
        },
    });


    const [createTracking] = useMutation(GQL_CREATE_TRACKING, {
        client: authClient,
        onCompleted: (data) => {
          setTrackings([...trackings, data.createTracking]);
        },
        onError: (error) => {
          console.error(error);
        },
    });

    const [updateFirstCardDraw] = useMutation(GQL_UPDATE_FIRST_CARD_DRAW, {
        client: authClient,
        onCompleted: (data) => {
          user?.id && void createTracking({ variables: { userId: user.id, thematicId: thematics.find(thematic => thematic.thematic.name === selected)?.thematic.id ?? 0 } });
          setUser(data.updateFirstCardDraw);
        },
        onError: (error) => {
          console.error(error);
        },
    });

    const handleValidate = async () => {
        if(!user?.id) return;
        selected.length > 0 && await updateFirstCardDraw({ variables: { userId: user.id  } });
    }

    return (
    <Container>
        <Card onClick={() => setClicked(true)} $clicked={clicked}>
            <img src={CardBack} alt="card-back" />
            <SText $fontFamily='Bangers' $fontsize='32px' $textShadow=' 2px 2px 0px #FFF;'>{i18n._(title)}</SText>
        </Card>
        {setCardDraw &&
            <BackButton onClick={() => setCardDraw && setCardDraw(false)}>
                <SText $fontsize='20px'>{i18n._('common.back')}</SText>
            </BackButton>
        }       
        <ThematicsContainer $clicked={clicked}>
            <Thematics>
                {thematics.map((thematic) => (
                    <Thematic key={thematic.thematic.id} $disabled={thematic.disabled} $selected={selected === thematic.thematic.name} onClick={() => thematic.disabled ? setSelected('') : selected === thematic.thematic.name ? setSelected('') : setSelected(thematic.thematic.name)}>
                        <img src={resolveImg({url: new URL(`@assets/images/`, import.meta.url).href,imageName: thematic.thematic.asset?? '',})} alt={thematic.thematic.name} />
                        <SText $fontFamily='Bangers' $fontsize='20px' >{thematic.thematic.name}</SText>
                        <SText $fontsize='12px' >{thematic.difficulty === ChallengeDifficulty.EASY ? 'Facile' : 'Complexe'}</SText>
                    </Thematic>
                ))}
            </Thematics>
            <SButton onClick={handleValidate} $width='60%' $disabled={selected.length === 0}>
                <SText $fontsize='20px'>{i18n._('common.validate')}</SText>
            </SButton>
        </ThematicsContainer>
    </Container>
    );
} 

export default CardDraw;

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
`;

const Card = styled.div<{ $clicked: boolean }>`
    position: absolute;
    left: 50%;
    bottom: ${({ $clicked }) => $clicked ? '-25%' : '50%'};
    width: ${({ $clicked }) => $clicked ? '50%' : '70%'};
    transform: ${({ $clicked }) => $clicked ? 'translateX(-50%) rotate(-4deg)' : 'translate(-50%, 50%) rotate(-4deg)'};
    transition: all 0.5s;
    && img {
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;
    }
`;

const ThematicsContainer = styled.div<{ $clicked: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    scale: ${({ $clicked }) => $clicked ? 1 : 0};
    transition: scale 1s;
`;

const Thematics = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    padding: 2%;
    margin-bottom: 15px;

    &&& > img {
        position: absolute;
        bottom: -100px;
        transform: rotate(-4deg) translateX(-50%);
        left: 50%;
        width: 168px;
        box-shadow: 9.818px 9.818px 0px 0px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }
`;

const Thematic = styled.div<{ $selected: boolean, $disabled: boolean }>`
    display: flex;
    width: 45%;
    height: 144px;
    padding: 17px 2px 5px 2px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid #000;
    z-index: 2;
    cursor: pointer;
    opacity: ${({ $disabled }) => $disabled ? 0.5 : 1};

    background: ${({ $selected }) => $selected ? '#fff' : 'unset'};
    box-shadow: ${({ $selected }) => $selected ? '0px 2px 0px 0px #000' : 'unset'};

    &&& > img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        border-radius: 8px;
    }
`;

const BackButton = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 10px 20px;
    background: #fff;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 2px 0px 0px #000;
`;